.simulationResult .kpiRow {
    display: flex;
    flex-direction: horizontal;
}

.simulationResult .kpiCard {
    flex: 1;
    margin-right: 20px;
}

.simulationResult .kpiCard:last-child {
    margin-right: 0px;
}

@media (max-width: 1040px){
    .simulationResult .kpiRow {
        flex-direction: column;
    }
    .simulationResult .kpiRow .kpiCard{
      flex: 1;
      margin-right: 0;
      margin-bottom: 20px;
    }
    .card.extendOnMobile{
      padding: 10px 0;
      box-shadow: none;
    }
  }

