.kpiCard .kpi{
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 0 0;
}
.kpiCard .subkpi{
    text-transform: uppercase;
    margin: 0 0 5px 0;
}

@media (max-width: 1040px){
    .kpiCard .kpi{
        margin: 0 5px 0 0;
        display: inline-block;
    }
    .kpiCard .subkpi{
        display: inline-block;
        margin: 0 0 0 0;
    }
  }