@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800');


.app {
  font-family: "Open sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: rgba(0,0,0,0.85);
  letter-spacing: 0.3px;
  line-height: 1.52857;
  max-width: 1000px;
  margin: auto;
}


h1{
  font-size: 38px;
  font-weight: 700;
  line-height: 1.23em;

}
h2{
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
  margin: 20px 0 10px 0;
}
h3{
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 1.25;
  margin: 20px 0 20px 0;
}

a, a:hover, a:visited{
  text-decoration: none;
  color: #000091;
}
ul{
  padding-left: 10px;
}

button{
  background-color: #FF6F4C;
  border-radius: 7px;
  color: #FFF;
  height: 32px;
  box-sizing: border-box;
  line-height: 32px;
  padding: 0 20px;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
button:active{
  background-color: white;
  border: 1px solid #FF6F4C;
  color: #FF6F4C;
}

button.calculateButton{
  width: 100%;
  height: 80px;
  font-size: 24px;
}

header .header-title{
  margin: 45px 0 15px 0;
}

header .header-title h1{
  display: inline-block;
  margin: 0;
  vertical-align: top;
  line-height: 50px;
}

.container{
  padding: 15px 0;
  box-sizing: border-box;
}

.row{
  display: flex;
  flex-direction: horizontal;
}
.row .col{
  flex: 1;
  margin-right: 30px;
}
.row .col:last-child{
  margin-right: 0;
}

.card{
	border: 0px solid black;
	padding: 10px 20px;
	border-radius: 16px;
	text-align: left;
	box-shadow: 0 0 0 transparent, 0 0 0 transparent, 6px 4px 25px #d6d6d6;
	background: #ffffff;
}

.card.startAnalysis{
  max-width: 500px;
  padding-bottom: 35px;
}

.kpiCard .kpi{
  display: block;
}
.kpi{
  font-size: 20px;
  font-weight: 700;
}
.kpiLegend{
  font-size: 12px;
  
}

.settings-form{
  display: block;
  flex-wrap: wrap;
}

.settings-form .entry{
  text-align:left;
  flex: 1 1 200px;
  box-sizing: border-box;
  margin: 0 0 20px 0;
}

.settings-form .entry .astuce{
  padding: 15px 20px 15px 20px;
  margin: 10px 20px 40px 10px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0 0 0 transparent, 0 0 0 transparent, 6px 4px 25px #d6d6d6;
}

.settings-form .entry input, .dateSelector input{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 3px;
  border: none;
  border-bottom: 2px solid #000091;
  outline: none;
  height: 32px;
  padding: 0 5px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: #000091;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.settings-form .entry input:focus, .dateSelector input:focus{
  border-bottom: 3px solid rgb(102,103,61);
  color: rgb(102,103,61);
}

.subchart{
  height: 150px;
}

.dateSelector{
  text-align: right;
}

footer {
  box-sizing: border-box;
  padding: 25px 0 0 0;
}

footer a{
  margin-right: 20px;
}

footer .footer-links{
  margin-bottom: 20px;
}

footer .tags{
  font-size: .8125em;
  margin-bottom: 20px;
}

.chartZone{
  user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

@media (max-width: 1040px){
  .app{
    margin: 0 20px;
  }
  .row{
    flex-direction: column;
  }
  .row .col{
    flex: 1;
    margin-right: 0;
  }
  .card.extendOnMobile{
    padding: 10px 0;
    box-shadow: none;
  }
}
